* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Element01 {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr;
  gap: 20px;
  margin: 30px auto 15px;
}

.text01 {
  border: solid 1px rgba(84, 113, 115);
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageValeria01 {
  width: 100%;
}

.gleichgewichtDiv {
  width: 100%;
  height: 80px;
  text-align: center;
  margin: 15px 0 15px;
}

.logo-001 {
  height: 100%;
}

.element02 {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr;
  gap: 20px;
  margin-bottom: 20px;
}

.text02 {
  border: solid 1px rgba(84, 113, 115);
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list {
  padding-left: 15px;
  margin-bottom: 20px;
}

.list li {
  font-family: system-ui;
  font-size: 0.95rem;
  color: #344647;
}

.kontaktBtn {
  height: 50px;
  width: 150px;
  margin: 20px;
  font-family: "Julius Sans One", sans-serif;
  font-size: 20px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: rgba(84, 113, 115);
  transition: 0.3s;
}

.kontaktBtn:hover {
  background-color: #344647;
}

.kontaktBtn a {
  text-decoration: none;
  color: white;
}

h1 {
  text-align: left;
  font-family: "Julius Sans One", sans-serif;
  color: rgba(84, 113, 115);
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 10px;
}

p {
  font-family: system-ui;
  font-size: 0.95rem;
  color: #344647;
}

@media (max-width: 1100px) {
  .Element01,
  .element02 {
    display: flex;
    flex-direction: column;
  }

  .text02 {
    order: -1;
  }
}
