.element03,
.element04 {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr;
  gap: 20px;
  margin: 30px auto 15px;
}

.text03 {
  border: solid 1px rgba(84, 113, 115);
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2 {
  text-align: left;
  font-family: "Julius Sans One", sans-serif;
  color: rgba(84, 113, 115);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.pbold {
  font-weight: 600;
}
.list {
  padding-left: 15px;
  margin-bottom: 20px;
}

.list li {
  font-family: system-ui;
  font-size: 0.95rem;
  color: #344647;
}

.logodiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gleichgewicht {
  height: 150px;
}

@media (max-width: 1100px) {
  .element03,
  .element04 {
    display: flex;
    flex-direction: column;
  }

  .text03 {
    order: -1;
  }
}
