* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.diashow {
  width: 100%;
  height: calc(70vw / 2 * 1.3);
  overflow: hidden;
}

.diashow-wrapper {
  width: 100%;
  position: relative;
  top: 0;
}

.imageWrapper {
  width: 100%;
  position: absolute;
  opacity: 0;
  transition: 2.5s ease-in-out;
}

.imageWrapper-active {
  opacity: 1;
}

.diashow-image {
  width: 100%;
}
@media (max-width: 1100px) {
  .diashow {
    height: calc(70vw * 1.4);
  }
}

@media (max-width: 700px) {
  .diashow {
    height: calc(90vw * 1.3);
  }
}
