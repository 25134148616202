.element05,
.element06 {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr;
  gap: 20px;
  margin: 30px auto 15px;
}

.valeria02 {
  width: 100%;
}

.text05,
.text06 {
  border: solid 1px rgba(84, 113, 115);
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list {
  padding-left: 15px;
}

.list li {
  font-family: system-ui;
  font-size: 0.95rem;
  color: #344647;
  margin-bottom: 13px;
}

.bold {
  font-weight: 600;
}

@media (max-width: 1100px) {
  .element05,
  .element06 {
    display: flex;
    flex-direction: column;
  }

  .text06 {
    order: -1;
  }
}
